var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Banners")]),_c('div',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},on:{"keyup":_vm.searchData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.openAddForm}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_vm._v(" New Banner ")],1),_c('banner-form',{ref:"form",attrs:{"show":_vm.isFormVisible,"form-type":_vm.formType},on:{"update:show":function($event){_vm.isFormVisible=$event},"onComplete":_vm.datatable}})],1)])],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","height":"64","max-width":"64","src":item.image_url}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.openEditForm(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteItemConfirmation(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]),_c('c-dialog',{on:{"onConfirm":_vm.deleteItem},model:{value:(_vm.isDeleteItemDialogVisible),callback:function ($$v) {_vm.isDeleteItemDialogVisible=$$v},expression:"isDeleteItemDialogVisible"}},[_vm._v(" Are you sure want to delete '"+_vm._s(_vm.selectedItem.name)+"'? ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }